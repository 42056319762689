/** @format */

import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {classes} from './styles';

interface SensorCounterProps {
  sensors: string[];
}

export const SensorCounter = ({sensors}: SensorCounterProps) => {
  const {t} = useTranslation();
  if (!sensors?.length) return null;
  const text = t('plurals.sensors', {count: sensors.length});

  return <Typography className={classes.sensorCounter}>{text}</Typography>;
};
