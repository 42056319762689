/** @format */
import {useState} from 'react';

import {LinearProgress} from '@mui/material';
import {gql, useMutation} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import WizardSidebar from 'components/MediaplanSidebar/WizardSidebar';

import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import WizardHeader from '../../WizardHeader';
import NameStep from '../../NameStep';

const CREATE_COMPANY = gql`
  mutation CreateCompany($name: String!) {
    createCompany(name: $name) {
      id
      uuid
      name
      tokens {
        id
        lastError
        provider
      }
    }
  }
`;

const WizardNew = () => {
  const navigate = useNavigate();

  const {mixpanel} = useMixpanelContext();
  const {switchCompany} = useCompanyContext();

  const [projectName, setPojectName] = useState('');
  const [saveCompanyError, setSaveCompanyError] = useState('');

  const [createCompany, {loading: createCompanyLoading}] =
    useMutation(CREATE_COMPANY);

  if (createCompanyLoading) return <LinearProgress style={{flex: 1}} />;

  const handleChange = e => {
    setPojectName(e.target.value);
  };

  const handleProjectNameConfirm = async () => {
    try {
      mixpanel.track('click_wizard_create_company_button');

      const {data: createCompanyData} = await createCompany({
        variables: {name: projectName},
      });

      switchCompany(createCompanyData.createCompany);

      navigate(
        `/home/project/wizard/created?companyId=${createCompanyData.createCompany.id}`,
      );
    } catch (e) {
      const message = e instanceof Error ? e.message : 'Unknown error.';
      setSaveCompanyError(message);
    }
  };

  return (
    <>
      <WizardSidebar activeStep={'CREATE'} />

      <WizardHeader />

      <NameStep
        onChange={handleChange}
        saveCompanyError={saveCompanyError}
        onConfirm={handleProjectNameConfirm}
        projectName={projectName}
      />
    </>
  );
};

export default WizardNew;
