/** @format */
import {useState, useEffect} from 'react';

import {gql, useQuery} from '@apollo/client';
import {styled} from '@mui/material/styles';
import {Trans, useTranslation} from 'react-i18next';
import Alert from '@mui/material/Alert';
import {Box, LinearProgress, Typography} from '@mui/material';

import WizardSidebar from 'components/MediaplanSidebar/WizardSidebar';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import LegacyTextField from 'components/inputs/LegacyTextField';
import PhoneNumberField from 'components/inputs/PhoneNumberField';

const PREFIX = 'CONSULT';

const classes = {
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
  input: `${PREFIX}-input`,
};

const StyledBox = styled(Box)(({_theme}) => ({
  [`& .${classes.title}`]: {
    color: '#F1F1F1',
    fontSize: '32px',
    fontWeight: 300,
    lineHeight: '40px',
  },
  [`& .${classes.text}`]: {
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  [`& .${classes.input}`]: {
    maxWidth: '468px',
  },
}));

interface currentUser {
  currentUser: {
    phone: string;
    profile?: {
      id: number;
      name?: string;
    };
  };
}

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      phone
      profile {
        id
        name
      }
    }
  }
`;

const WizardConsult = () => {
  const {t} = useTranslation();

  const [state, setState] = useState({phone: '', name: ''});
  const [sentSuccess, setSentSuccess] = useState(false);

  const {loading, error, data} = useQuery<currentUser>(GET_CURRENT_USER);

  useEffect(() => {
    if (data != null) {
      const {phone, profile} = data.currentUser;

      const name = profile && profile.name ? profile.name : '';
      setState({phone, name});
    }
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) {
    return <Alert severity='error'>{error}</Alert>;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setState({...state, [name]: value});
  };

  const handleChangePhone = (value: string) => {
    setState({...state, phone: value});
  };

  const handleClick = async () => {
    await fetch(
      'https://hook.integromat.com/rl2fvk8y0ohs7t5jnvdccqmxa03f1jis',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      },
    );
    setSentSuccess(true);
  };

  return (
    <StyledBox>
      <WizardSidebar activeStep={'CONSULT'} />

      <Typography className={classes.title}>
        <Trans>Заказать консультацию</Trans>
      </Typography>

      {sentSuccess && (
        <Box sx={{mt: 16}}>
          <Typography className={classes.text}>
            <Trans>Заявка на консультацию отправлена</Trans>
          </Typography>
          <Typography className={classes.text}>
            <Trans>Мы свяжемся с вами в ближайшее время!</Trans>
          </Typography>
        </Box>
      )}
      {!sentSuccess && (
        <>
          <Box sx={{mt: 16}}>
            <Typography className={classes.text}>
              <Trans>
                Оставьте заявку, наш менеджер свяжется с вами и поможет начать
                пользоваться сервисом
              </Trans>
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 4,
            }}
          >
            <LegacyTextField
              inputProps={{name: 'name'}}
              myProps={{placeholderFocused: ''}}
              placeholder={t('Ваше имя', 'Ваше имя')}
              value={state.name}
              onChange={handleChange}
              className={classes.input}
            />
          </Box>
          <Box
            sx={{
              mt: 4,
            }}
          >
            <PhoneNumberField
              value={state.phone}
              onChange={handleChangePhone}
              className={classes.input}
            />
          </Box>
          <Box
            sx={{
              mt: 4,
            }}
          >
            <PrimaryMediumButton
              disabled={loading || !state.phone || !state.name}
              onClick={handleClick}
            >
              <Trans>Отправить заявку</Trans>
            </PrimaryMediumButton>
          </Box>
        </>
      )}
    </StyledBox>
  );
};

export default WizardConsult;
