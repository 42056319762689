/** @format */

import {Box, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {styled} from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import LegacyTextField from 'components/inputs/LegacyTextField';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

import StepWrapper from './StepWrapper';

const PREFIX = 'CREATE';

const classes = {
  sidebar: `${PREFIX}-sidebar`,
  stepButton: `${PREFIX}-stepButton`,
  stepButtonIcon: `${PREFIX}-stepButtonIcon`,
  stepWrapper: `${PREFIX}-stepWrapper`,
  stepHeader: `${PREFIX}-stepHeader`,
  stepDescription: `${PREFIX}-stepDescription`,
  nameInput: `${PREFIX}-nameInput`,
  adButtonWrapper: `${PREFIX}-adButtonWrapper`,
  adButton: `${PREFIX}-adButton`,
  adButtonActive: `${PREFIX}-adButtonActive`,
  adButtonIcon: `${PREFIX}-adButtonIcon`,
  projectCreatedWrapper: `${PREFIX}-projectCreatedWrapper`,
  projectCreatedIcon: `${PREFIX}-projectCreatedIcon`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.stepDescription}`]: {
    marginTop: theme.spacing(4),
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  [`& .${classes.nameInput}`]: {
    width: '468px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  [`& .${classes.adButtonWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  [`& .${classes.adButton}`]: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    background: '#202020',
    borderRadius: '0',
    '&:hover': {
      background: '#4D4D4D',
    },
    '&:first-of-type': {
      borderRadius: '8px 0 0 8px',
    },
    '&:last-of-type': {
      borderRadius: '0 8px 8px 0',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      borderRadius: '0 !important',
    },
  },
  [`& .${classes.adButtonActive}`]: {
    background: '#4D4D4D',
  },
  [`& .${classes.adButtonIcon}`]: {
    width: 21,
    height: 21,
    marginRight: theme.spacing(2),
  },
  [`& .${classes.projectCreatedWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.projectCreatedIcon}`]: {
    marginRight: theme.spacing(2),
    color: '#749F23',
    fontSize: '20px',
  },
}));

interface Props {
  saveCompanyError: string;
  onChange: (e: any) => void;
  projectName: string;
  onConfirm?: () => void;
  isProjectCreated?: boolean;
  isEditPage?: boolean;
}

const NameStep = (props: Props) => {
  const {t} = useTranslation();

  return (
    <StyledBox>
      <StepWrapper
        header={
          <>
            1. <Trans>Название</Trans>
          </>
        }
      >
        <Typography className={classes.stepDescription}>
          <Trans>По названию можно будет быстро найти проект в списке</Trans>
        </Typography>
        <Box sx={{mt: 4}}>
          <LegacyTextField
            error={Boolean(props.saveCompanyError)}
            className={classes.nameInput}
            value={props.projectName || ''}
            placeholder={t('Название проекта', 'Название проекта')}
            inputProps={{name: 'name'}}
            onChange={props.onChange}
          />
        </Box>

        {!props.isEditPage &&
          (props.isProjectCreated ? (
            <>
              <Box sx={{mt: 4}} className={classes.projectCreatedWrapper}>
                <CheckCircleIcon className={classes.projectCreatedIcon} />
                <Typography>
                  <Trans>Проект создан</Trans>
                </Typography>
              </Box>

              <Box sx={{mt: 2}}>
                <Typography>
                  <Trans>
                    Теперь он доступен в системе. Чтобы его использовать, нужно
                    выбрать рекламную систему и подключить доступы
                  </Trans>
                </Typography>
              </Box>
            </>
          ) : (
            <Box sx={{mt: 4}}>
              <PrimaryMediumButton
                disabled={!props.projectName.length}
                onClick={props.onConfirm && props.onConfirm}
              >
                <Trans>Продолжить</Trans>
              </PrimaryMediumButton>
            </Box>
          ))}
      </StepWrapper>
    </StyledBox>
  );
};

export default NameStep;
