/** @format */
import {Box, Link} from '@mui/material';
import {Trans} from 'react-i18next';
import {clsx} from 'clsx';

import AddIcon from '@mui/icons-material/Add';
import PhoneIcon from '@mui/icons-material/Phone';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import {StyledBox, classes} from '../styles';

const STEPS = [
  {
    name: 'Подключение',
    key: 'CREATE',
    icon: <AddIcon className={classes.stepButtonIcon} />,
    href: '/home/project/wizard/new',
  },
  {
    name: 'Заказать консультацию',
    key: 'CONSULT',
    icon: <PhoneIcon className={classes.stepButtonIcon} />,
    href: '/home/project/wizard/consult',
  },
];

const STEPS_HELP = [
  {
    name: 'Справка',
    key: 'HELP',
    icon: <OpenInNewIcon className={classes.stepButtonIcon} />,
    href: 'https://adsensor.ru/instructions',
  },
  {
    name: 'Специалистам',
    key: 'SPECIALISTS',
    icon: <OpenInNewIcon className={classes.stepButtonIcon} />,
    href: 'https://adsensor.ru/all-sensors',
  },
];

interface Props {
  activeStep: string;
  onSaveAll?: () => void;
}

const WizardSidebar = (props: Props) => {
  return (
    <StyledBox>
      <Box className={classes.wizardSidebar}>
        <Box className={classes.wizardItem}>
          {STEPS.map(step => (
            <Link
              key={step.key}
              className={clsx(
                classes.stepButton,
                step.key === 'CREATE' &&
                  STEPS.length === 1 &&
                  !props.onSaveAll &&
                  classes.stepButtonCreate,
                props.activeStep === step.key && classes.stepButtonActive,
              )}
              href={step.href}
            >
              {step.icon}

              <Trans>{step.name}</Trans>
            </Link>
          ))}
        </Box>
        <Box sx={{mt: 4}} className={classes.wizardItem}>
          {STEPS_HELP.map(step => (
            <Link
              key={step.key}
              className={clsx(
                classes.stepButton,
                step.key === 'CREATE' &&
                  STEPS.length === 1 &&
                  !props.onSaveAll &&
                  classes.stepButtonCreate,
                props.activeStep === step.key && classes.stepButtonActive,
              )}
              href={step.href}
              target='_blank'
            >
              {step.icon}

              <Trans>{step.name}</Trans>
            </Link>
          ))}
        </Box>
      </Box>
    </StyledBox>
  );
};

export default WizardSidebar;
