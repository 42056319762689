/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

const PREFIX = 'MEDIAPLAN_SIDEBAR';

const SIDEBAR_WIDTH = 272;

export const classes = {
  sidebar: `${PREFIX}-sidebar`,
  wizardSidebar: `${PREFIX}-wizardSidebar`,
  wizardItem: `${PREFIX}-wizardItem`,
  stepButton: `${PREFIX}-stepButton`,
  stepButtonActive: `${PREFIX}-stepButtonActive`,
  stepButtonIcon: `${PREFIX}-stepButtonIcon`,
  statusDoneIcon: `${PREFIX}-statusDoneIcon`,
  stepButtonHeading: `${PREFIX}-stepButtonHeading`,
  stepButtonCreate: `${PREFIX}-stepButtonCreate`,
  stepButtonIconHeading: `${PREFIX}-stepButtonIconHeading`,
  stepButtonSaveAll: `${PREFIX}-stepButtonSaveAll`,
};

export const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.sidebar}`]: {
    position: 'absolute',
    top: 59,
    left: 0,
    width: `${SIDEBAR_WIDTH}px`,
    minHeight: '100vh',
    height: 'auto',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xl')]: {
      position: 'relative',
      top: 'auto',
      minHeight: 'auto',
      width: '100%',
      marginBottom: theme.spacing(10),
      borderRadius: ' 8px',
      overflow: 'hidden',
    },
  },
  [`& .${classes.wizardSidebar}`]: {
    position: 'absolute',
    top: 59,
    left: 0,
    width: `${SIDEBAR_WIDTH}px`,
    minHeight: '100vh',
    height: 'auto',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xl')]: {
      position: 'relative',
      top: 'auto',
      minHeight: 'auto',
      width: '100%',
      marginBottom: theme.spacing(10),
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  [`& .${classes.wizardItem}`]: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      width: '49%',
      marginTop: 0,
      borderRadius: ' 8px',
      overflow: 'hidden',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 0,
      '&:last-of-type': {
        marginTop: theme.spacing(2),
      },
    },
  },
  [`& .${classes.stepButton}`]: {
    width: '100%',
    padding: theme.spacing(4.5, 8),
    background: '#202020',
    display: 'flex',
    alignItems: 'center',
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    cursor: 'pointer',
    '&:hover': {
      background: '#383838',
    },
    '&:first-of-type': {
      borderRadius: ' 0 8px 0 0 ',
    },
    '&:last-of-type': {
      borderRadius: ' 0 0 8px 0 ',
    },
    [theme.breakpoints.down('xl')]: {
      '&:first-of-type': {
        borderRadius: ' 0',
      },
      '&:last-of-type': {
        borderRadius: ' 0 ',
      },
    },
  },
  [`& .${classes.stepButtonSaveAll}`]: {
    background: '#749F23',
    '&:hover': {
      background: '#749F23',
    },
  },
  [`& .${classes.stepButtonCreate}`]: {
    borderRadius: '0 8px 8px 0 !important',
  },
  [`& .${classes.stepButtonHeading}`]: {
    padding: theme.spacing(2, 8),
    background: '#2B2B2B',
    color: '#8B8B8B',
  },
  [`& .${classes.stepButtonActive}`]: {
    background: '#383838',
  },
  [`& .${classes.stepButtonIcon}`]: {
    marginRight: theme.spacing(2),
    color: '#F1F1F1',
    fontSize: '20px',
  },
  [`& .${classes.stepButtonIconHeading}`]: {
    color: '#8B8B8B',
  },
  [`& .${classes.statusDoneIcon}`]: {
    marginLeft: 'auto',
    color: '#749F23',
    fontSize: '24px',
  },
}));
