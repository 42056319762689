/** @format */

import {useState, useEffect} from 'react';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  startDate: any;
  endDate: any;
  onConfirm: (startDate: Date, endDate: Date) => void;
}

const DateRange = (props: Props) => {
  const [dateRange, setDateRange] = useState([props.startDate, props.endDate]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (startDate && endDate) {
      props.onConfirm(startDate, endDate);
    }
  }, [dateRange, endDate, props, startDate]);

  return (
    <DatePicker
      selectsRange
      startDate={startDate}
      endDate={endDate}
      portalId='root'
      onChange={update => {
        setDateRange(update);
      }}
      isClearable
    />
  );
};

export default DateRange;
