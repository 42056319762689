/** @format */

import {Navigate, Route, Routes} from 'react-router-dom';

import {ROUTES} from 'constants/ROUTES';

import PrivateRoute from 'components/PrivateRoute';

import Main from './Main';
import Project from './Project';
import Settings from './Settings';
import DigitalCast from './DigitalCast';

const Home = () => {
  return (
    <Routes>
      <Route
        path={`${ROUTES.MAIN}/*`}
        element={
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        }
      />
      <Route
        path={`${ROUTES.DIGITAL_CAST}/*`}
        element={
          <PrivateRoute>
            <DigitalCast />
          </PrivateRoute>
        }
      />
      <Route
        path={`${ROUTES.PROJECT}/*`}
        element={
          <PrivateRoute>
            <Project />
          </PrivateRoute>
        }
      />
      <Route
        path={`${ROUTES.SETTINGS}/*`}
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      />
      <Route index element={<Navigate to={ROUTES.MAIN} />} />
    </Routes>
  );
};

export default Home;
