/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';

const PREFIX = 'MEDIAPLAN_SIDEBAR';

export const classes = {
  pageContainer: `${PREFIX}-pageContainer`,
  sensorDescriptionWrapper: `${PREFIX}-sensorDescriptionWrapper`,
  normalSensorsIcon: `${PREFIX}-normalSensorsIcon`,
  sensorDescriptionIcon: `${PREFIX}-sensorDescriptionIcon`,
  unusedSensorsAmount: `${PREFIX}-unusedSensorsAmount`,
  saveAllButton: `${PREFIX}-saveAllButton`,
  saveAllButtonWrapper: `${PREFIX}-saveAllButtonWrapper`,
  sensorGroupsContainer: `${PREFIX}-sensorGroupsContainer`,
};

export const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.pageContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,

    [theme.breakpoints.down('xl')]: {
      paddingBottom: theme.spacing(20),
    },
  },
  [`& .${classes.sensorDescriptionWrapper}`]: {
    display: 'flex',
    gap: 8,
  },
  [`& .${classes.sensorDescriptionIcon}`]: {
    height: 16,
    width: 16,
  },
  [`& .${classes.normalSensorsIcon}`]: {
    transform: 'rotate(-90deg) scale(1.5)',
  },
  [`& .${classes.sensorGroupsContainer}`]: {
    paddingBlock: theme.spacing(8),
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyContent: 'space-between',
    gap: 16,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  [`& .${classes.saveAllButton}`]: {
    background: theme.palette.success.main,
    borderRadius: 8,
    display: 'flex',
    height: '48px',
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(4),
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    width: 'fit-content',
  },
  [`& .${classes.saveAllButtonWrapper}`]: {
    position: 'fixed',
    bottom: 0,
    background: theme.palette.background?.default,
    width: '100vw',
    height: theme.spacing(20),
    paddingBlock: theme.spacing(4),
  },
  [`& .${classes.unusedSensorsAmount}`]: {
    display: 'flex',
    padding: theme.spacing(2.5),
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '8px',
    width: 'fit-content',
    background: '#202020',
    fontSize: '14px',
  },
}));
