/** @format */

import {PlayArrow} from '@mui/icons-material';
import Circle from '@mui/icons-material/Circle';
import Square from '@mui/icons-material/Square';
import {Box, Typography} from '@mui/material';
import {clsx} from 'clsx';
import {Trans, useTranslation} from 'react-i18next';

import SidebarNew from 'components/MediaplanSidebar/SidebarNew';
import theme from 'theme';

import {SensorGroup} from './components/SensorGroup';
import {StyledBox, classes} from './styles';

const categories = [
  {
    name: 'Критичные',
    icon: (
      <Square
        sx={{height: 22, width: 22}}
        htmlColor={theme.palette.figma_red_1?.main}
      />
    ),
    sensors: [
      'as-ad-lim-links',
      'as-ad-lim-title',
      'as-ad-limit-exceeded-cpc',
      'as-ad-limit-threshold-unreached-cpm',
      'as-ad-link-mismatch',
      'as-ad-mobile-title-copy',
      'as-ad-noconv',
      'as-ad-second-desc',
      'as-ad-second-title',
      'as-ad-spent-dropped',
      'as-ad-stop-words',
      'as-ad-stopped',
      'as-ad-title-copy',
      'as-ad-title-nokey',
      'as-ad-vcard',
      'as-adgroup-noconv',
      'as-adgroup-stopped',
      'as-autotargeting-high-bid',
      'as-autotargeting-manual-bid-mix',
      'as-bad-keys',
      'as-brand-traffic-anomaly-day',
      'as-brand-traffic-anomaly-week',
      'as-campaign-limited',
      'as-campaign-low-day-spent',
      'as-campaign-no-rsa',
      'as-campaign-noclicks',
      'as-campaign-noconv',
      'as-campaign-priority-goals',
      'as-campaign-stopped',
      'as-clicks-dropped',
      'as-conversion-dropped',
      'as-counters',
      'as-different-domains',
      'as-fade',
      'as-feed-404',
    ],
  },
  {
    name: 'Обычные',
    icon: (
      <PlayArrow
        sx={{
          height: 22,
          width: 22,
          transform: 'rotate(-90deg) scale(1.5)',
        }}
        htmlColor={theme.palette.figma_brown_1?.main}
      />
    ),
    sensors: [
      'as-404',
      'as-account-limit',
      'as-account-limit-exceeded',
      'as-ad-anomaly-fall-clicks',
      'as-ad-anomaly-fall-conversions',
      'as-ad-anomaly-fall-cpa',
      'as-ad-anomaly-fall-cpc',
      'as-ad-anomaly-fall-cpm',
      'as-ad-anomaly-fall-cr',
      'as-ad-anomaly-fall-ctr',
      'as-ad-anomaly-fall-impressions',
      'as-ad-anomaly-grow-clicks',
      'as-ad-anomaly-grow-conversions',
      'as-ad-anomaly-grow-cpa',
      'as-ad-anomaly-grow-cpc',
      'as-ad-anomaly-grow-cpm',
      'as-ad-anomaly-grow-cr',
      'as-ad-anomaly-grow-ctr',
      'as-ad-anomaly-grow-impressions',
      'as-ad-anomaly-threshold-unreached-cpc',
      'as-ad-double',
      'as-ad-extensions',
      'as-ad-header',
      'as-ad-high-bounce-rate',
      'as-feed-escape-problem',
      'as-feed-not-accessible',
      'as-feed-not-updated',
      'as-feed-offer-double',
      'as-feed-offer-name-desc-limit',
      'as-feed-offer-no-picture',
      'as-feed-offer-not-available',
      'as-feed-picture-404',
      'as-geo',
      'as-geo-area-of-interest',
      'as-geo-card',
      'as-geo-domain',
      'as-geo-name-mismatch',
      'as-geo-text',
      'as-geo-view-link',
      'as-group-ad-count',
      'as-high-imp-ratio',
      'as-impression-dropped',
      'as-key-high-bounce-rate',
      'as-key-low-conv',
      'as-lead-form-email-empty',
      'as-limit',
    ],
  },
  {
    name: 'Некритичные',
    icon: (
      <Circle
        sx={{height: 22, width: 22}}
        htmlColor={theme.palette.figma_gray_middle_dark?.main}
      />
    ),
    sensors: [
      'as-limit-exceeded',
      'as-low-balance',
      'as-low-pos-brand',
      'as-low-relative-ctr',
      'as-low-spend-cap',
      'as-mod',
      'as-monitoring',
      'as-network-excluded-sites',
      'as-nowork-minus',
      'as-pixel',
      'as-place-low-goal-conv',
      'as-raise-dead',
      'as-sale',
      'as-search-minus',
      'as-sitelink-no-desc',
      'as-spend-key',
      'as-spent',
      'as-stop-lim',
      'as-strat-cpa',
      'as-strat-low-conv',
      'as-strat-maxconv-low-conv',
      'as-strat-week-limit',
      'as-typo',
      'as-url-fail',
      'as-url-struct',
      'as-use-utm-adwords',
      'as-utm',
      'as-utm-coherency',
      'as-utm-format',
      'as-utm-format-appsflyer',
      'as-utm-in-link',
      'as-utm-param-double',
      'as-utm-presence',
      'as-utm-settings',
    ],
  },
];

const Sensors = () => {
  const {t} = useTranslation();
  const sensorsUnused = t('plurals.sensors_unused', {count: 140});

  return (
    <>
      <SidebarNew activeStep={'SENSORS'} enableAll onSaveAll={() => {}} />

      <StyledBox>
        <Box className={classes.pageContainer}>
          <Typography variant='h1' sx={{fontWeight: 300}}>
            <Trans>Сенсоры</Trans>
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
            <Typography variant='body2'>
              <Trans>
                Чтобы сенсоры медиаплана работали, нужно настроить их важность
              </Trans>
            </Typography>
            <Box className={classes.sensorDescriptionWrapper}>
              <Square
                className={classes.sensorDescriptionIcon}
                htmlColor={theme.palette.figma_red_1?.main}
              />
              <Typography variant='body2'>
                <Trans>
                  Критичные сенсоры отправляют уведомления о срабатывании и
                  выводятся выше остальных
                </Trans>
              </Typography>
            </Box>
            <Box className={classes.sensorDescriptionWrapper}>
              <PlayArrow
                className={clsx(
                  classes.sensorDescriptionIcon,
                  classes.normalSensorsIcon,
                )}
                htmlColor={theme.palette.figma_brown_1?.main}
              />
              <Typography variant='body2'>
                <Trans>Обычные сенсоры выводятся после критичных</Trans>
              </Typography>
            </Box>
            <Box className={classes.sensorDescriptionWrapper}>
              <Circle
                className={classes.sensorDescriptionIcon}
                htmlColor={theme.palette.figma_gray_middle_dark?.main}
              />
              <Typography variant='body2'>
                <Trans>
                  Некритичные сенсоры видны только на странице проекта
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.sensorGroupsContainer}>
            {categories.map(({name, icon, sensors}) => (
              <SensorGroup
                key={name}
                name={name}
                icon={icon}
                sensors={sensors}
                onRemove={() => {}}
                onAdd={() => {}}
              />
            ))}
          </Box>
          <Box className={classes.unusedSensorsAmount}>{sensorsUnused}</Box>
          <Box className={classes.saveAllButtonWrapper}>
            <Box className={classes.saveAllButton}>
              <Trans>Сохранить медиаплан и вернуться на страницу проекта</Trans>
            </Box>
          </Box>
        </Box>
      </StyledBox>
    </>
  );
};

export default Sensors;
