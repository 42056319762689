/** @format */
import {useState} from 'react';

import {Box, Typography, LinearProgress} from '@mui/material';
import {Trans} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import SidebarNew from 'components/MediaplanSidebar/SidebarNew';

import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import NameStep from '../../NameStep';

const CREATE_COMPANY = gql`
  mutation CreateCompany($name: String!) {
    createCompany(name: $name) {
      id
      uuid
      name
      tokens {
        id
        lastError
        provider
      }
    }
  }
`;

const Create = () => {
  const navigate = useNavigate();

  const {mixpanel} = useMixpanelContext();
  const {switchCompany} = useCompanyContext();

  const [projectName, setPojectName] = useState('');
  const [saveCompanyError, setSaveCompanyError] = useState('');

  const [createCompany, {loading: createCompanyLoading}] =
    useMutation(CREATE_COMPANY);

  if (createCompanyLoading) return <LinearProgress style={{flex: 1}} />;

  const handleChange = e => {
    setPojectName(e.target.value);
  };

  const handleProjectNameConfirm = async () => {
    try {
      mixpanel.track('click_wizard_create_company_button');

      const {data: createCompanyData} = await createCompany({
        variables: {name: projectName},
      });

      switchCompany(createCompanyData.createCompany);

      navigate(
        `/home/project/created?companyId=${createCompanyData.createCompany.id}`,
      );
    } catch (e) {
      const message = e instanceof Error ? e.message : 'Unknown error.';
      setSaveCompanyError(message);
    }
  };

  return (
    <>
      <SidebarNew activeStep={'CREATE'} />

      <Box>
        <Typography variant='h1'>
          <Trans>Создание проекта</Trans>
        </Typography>

        <NameStep
          onChange={handleChange}
          saveCompanyError={saveCompanyError}
          onConfirm={handleProjectNameConfirm}
          projectName={projectName}
        />
      </Box>
    </>
  );
};

export default Create;
