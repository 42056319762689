/** @format */
import React from 'react';

import {clsx} from 'clsx';
import {Box, Typography, Button} from '@mui/material';
import {styled} from '@mui/material/styles';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import {Trans, useTranslation} from 'react-i18next';

import imageHelpers from 'helpers/imageHelpers';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

import type {Provider} from 'types';

const PREFIX = 'TOKEN_STEP';

const classes = {
  sidebar: `${PREFIX}-sidebar`,
  stepButton: `${PREFIX}-stepButton`,
  stepButtonIcon: `${PREFIX}-stepButtonIcon`,
  stepWrapper: `${PREFIX}-stepWrapper`,
  stepHeader: `${PREFIX}-stepHeader`,
  stepDescription: `${PREFIX}-stepDescription`,
  nameInput: `${PREFIX}-nameInput`,
  adButtonWrapper: `${PREFIX}-adButtonWrapper`,
  adButton: `${PREFIX}-adButton`,
  adButtonActive: `${PREFIX}-adButtonActive`,
  adButtonIcon: `${PREFIX}-adButtonIcon`,
  projectCreatedWrapper: `${PREFIX}-projectCreatedWrapper`,
  projectCreatedIcon: `${PREFIX}-projectCreatedIcon`,
  subbaccountsWrapper: `${PREFIX}-subbaccountsWrapper`,
  subbaccountsHeader: `${PREFIX}-subbaccountsHeader`,
  subbaccountsEditButton: `${PREFIX}-subbaccountsEditButton`,
  subbaccountsEditButtonIcon: `${PREFIX}-subbaccountsEditButtonIcon`,
  subbaccountsList: `${PREFIX}-subbaccountsList`,
  subbaccountsListItem: `${PREFIX}-subbaccountsListItem`,
  removeTokenButton: `${PREFIX}-removeTokenButton`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.stepDescription}`]: {
    marginTop: theme.spacing(4),
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  [`& .${classes.nameInput}`]: {
    width: '468px',
  },
  [`& .${classes.projectCreatedWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.projectCreatedIcon}`]: {
    marginRight: theme.spacing(2),
    color: '#749F23',
    fontSize: '20px',
  },
  [`& .${classes.adButtonWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  [`& .${classes.adButton}`]: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    height: '53px',
    color: '#F1F1F1',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    background: '#202020',
    borderRadius: '0',
    '&:hover': {
      background: '#4D4D4D',
    },
    '&:first-of-type': {
      borderRadius: '8px 0 0 8px',
    },
    '&:last-of-type': {
      borderRadius: '0 8px 8px 0',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      borderRadius: '0 !important',
    },
  },
  [`& .${classes.adButtonActive}`]: {
    background: '#4D4D4D',
  },
  [`& .${classes.adButtonIcon}`]: {
    width: 21,
    height: 21,
    marginRight: theme.spacing(2),
  },
  [`& .${classes.subbaccountsWrapper}`]: {
    maxWidth: '468px',
    width: '100%',
    borderRadius: '8px',
    background: '#202020',
    overflow: 'hidden',
  },
  [`& .${classes.subbaccountsHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#2B2B2B',
    color: '#8B8B8B',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '18px',
    letterSpacing: '0.32px',
  },
  [`& .${classes.subbaccountsEditButton}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  [`& .${classes.subbaccountsEditButtonIcon}`]: {
    marginRight: theme.spacing(2),
    color: '#8B8B8B',
    fontSize: '16px',
  },
  [`& .${classes.subbaccountsList}`]: {
    background: '#202020',
    padding: theme.spacing(4),
  },
  [`& .${classes.subbaccountsListItem}`]: {
    marginTop: theme.spacing(1),
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  [`& .${classes.removeTokenButton}`]: {
    padding: theme.spacing(5, 4),
    borderRadius: '8px',
    background: '#202020',
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
}));

interface Props {
  token: Token | null;
  isAnalyticsStep?: boolean;
  activeProvider?: Provider | null;
  onEditSubbaccounts: (v: Provider) => void;
  onRemoveToken: (v: Provider) => void;
  onAdCompanyClick: (v: Provider | null) => void;
  onAdConnect: (v: Provider) => void;
}

const providers = [
  'YANDEX',
  'VKONTAKTE',
  'VKONTAKTE_ADS',
  'GOOGLE',
] as Provider[];

export interface Token {
  id: number;
  provider: Provider;
  accounts: {
    id: number;
    name: string;
  }[];
}

const TokenStep = (props: Props) => {
  const {t} = useTranslation();

  const providerImage = (v: Provider) => imageHelpers.providerIconSrc(v, 20);
  const providerName = (v: Provider) => t(`provider_names.${v}`);

  const renderPermissions = (provider: Provider) => {
    return (
      <Box>
        <Box sx={{mt: 12}}>
          <Typography className={classes.stepDescription}>
            <Trans>Подключите доступы</Trans>
          </Typography>
        </Box>
        <Box sx={{mt: 2}}>
          <Typography className={classes.stepDescription}>
            <Trans>
              Вы перейдете на страницу авторизации, где нужно будет ввести адрес
              почты, привязанный к рекламному кабинету, и пароль.
            </Trans>
          </Typography>
        </Box>

        <Box sx={{mt: 4}}>
          <PrimaryMediumButton onClick={() => props.onAdConnect(provider)}>
            <Trans>Подключить</Trans>
          </PrimaryMediumButton>
        </Box>
      </Box>
    );
  };

  const renderProvidersMenu = () => {
    return (
      <>
        <Typography className={classes.stepDescription}>
          <Trans>Выберите рекламную систему</Trans>
        </Typography>

        <Box className={classes.adButtonWrapper}>
          {providers.map((provider: Provider, index: number) => (
            <Button
              className={clsx(
                classes.adButton,
                props.activeProvider === provider && classes.adButtonActive,
              )}
              key={index}
              onClick={() => props.onAdCompanyClick(provider)}
            >
              <img
                alt='status'
                src={
                  new URL(providerImage(provider) || '', import.meta.url).href
                }
                className={classes.adButtonIcon}
              />
              {providerName(provider)}
            </Button>
          ))}
          <Button
            className={clsx(
              classes.adButton,
              !props.activeProvider && classes.adButtonActive,
            )}
            onClick={() => props.onAdCompanyClick(null)}
          >
            <Trans>Не добавлять</Trans>
          </Button>
        </Box>
      </>
    );
  };

  const renderMetrikaConnect = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 12,
          }}
        >
          <img
            alt='status'
            src={new URL(providerImage('METRIKA') || '', import.meta.url).href}
            className={classes.adButtonIcon}
          />
          <Typography>
            <Trans>Подключите Яндекс Метрику</Trans>
          </Typography>
        </Box>
        <Box sx={{mt: 2}}>
          <Typography>
            <Trans>
              Вы перейдете на страницу авторизации Яндекса, где нужно будет
              ввести адрес почты, привязанный к рекламному кабинету, и пароль.
            </Trans>
          </Typography>
        </Box>

        <Box sx={{mt: 4}}>
          <PrimaryMediumButton onClick={() => props.onAdConnect('METRIKA')}>
            <Trans>Подключить</Trans>
          </PrimaryMediumButton>
        </Box>
      </>
    );
  };

  const renderConnectedProvider = () => {
    return (
      <>
        <Box sx={{mt: 12}}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 12,
            }}
          >
            <img
              alt='status'
              src={
                new URL(
                  providerImage(props.token.provider) || '',
                  import.meta.url,
                ).href
              }
              className={classes.adButtonIcon}
            />
            <Typography>
              <Trans>{providerName(props.token.provider)} подключен</Trans>
            </Typography>
          </Box>

          <Box sx={{mt: 2}} className={classes.subbaccountsWrapper}>
            <Box className={classes.subbaccountsHeader}>
              <Box sx={{p: 4}}>
                <Typography>
                  <Trans>Выбранные суббаккаунты</Trans>
                </Typography>
              </Box>

              <Button
                className={classes.subbaccountsEditButton}
                onClick={() => props.onEditSubbaccounts(props.token.provider)}
              >
                <ModeEditOutlineOutlinedIcon
                  className={classes.subbaccountsEditButtonIcon}
                />
                <Trans>Изменить</Trans>
              </Button>
            </Box>
            <Box className={classes.subbaccountsList}>
              {props.token.accounts.map(v => (
                <Box key={v.id} className={classes.subbaccountsListItem}>
                  {v.name}
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={{mt: 4}}>
            <Button
              className={classes.removeTokenButton}
              onClick={() => props.onRemoveToken(props.token.provider)}
            >
              <Trans>Отключить аккаунт</Trans>
            </Button>
          </Box>
        </Box>
      </>
    );
  };
  return (
    <StyledBox>
      {props.token ? (
        renderConnectedProvider()
      ) : (
        <>
          {!props.isAnalyticsStep
            ? renderProvidersMenu()
            : renderMetrikaConnect()}

          {props.activeProvider &&
            !props.isAnalyticsStep &&
            renderPermissions(props.activeProvider)}
        </>
      )}
    </StyledBox>
  );
};

export default TokenStep;
