/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Trans} from 'react-i18next';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';

const PREFIX = 'TOTAL_INFO';

const classes = {
  spentInfoWrapper: `${PREFIX}-spentInfoWrapper`,
  spentInfoPeriod: `${PREFIX}-spentInfoPeriod`,
  spentInfoAmount: `${PREFIX}-spentInfoAmount`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.spentInfoWrapper}`]: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '314px',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(8),
    borderRadius: '8px',
    background: '#383838',
    color: '#F1F1F1',
    fontSize: '16px',
    fontWeight: 400,
  },
  [`& .${classes.spentInfoPeriod}`]: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0.2px',
  },
  [`& .${classes.spentInfoAmount}`]: {
    marginTop: theme.spacing(2),
    fontSize: '32px',
  },
}));

interface Props {
  period?: string;
  amount: string;
  periodDays?: number;
  spentPerDay?: string;
  isSpentInfo?: boolean;
  isAverageInfo?: boolean;
}

const TotalInfo = ({
  period,
  amount,
  periodDays,
  spentPerDay,
  isSpentInfo,
  isAverageInfo,
}: Props) => {
  return (
    <StyledBox>
      <Box className={classes.spentInfoWrapper}>
        {period && <Box className={classes.spentInfoPeriod}>{period}</Box>}
        <Box className={classes.spentInfoAmount}>
          {amount}
          {isAverageInfo && <Trans> μ</Trans>}
          {isSpentInfo && <CurrencyRubleIcon />}
        </Box>
        {periodDays && (
          <Box sx={{mt: 2}}>
            <Trans>за</Trans> {periodDays} <Trans>дней</Trans>
          </Box>
        )}
        {spentPerDay && (
          <Box sx={{mt: 2}}>
            ~ {spentPerDay} <Trans>в день</Trans>
          </Box>
        )}
      </Box>
    </StyledBox>
  );
};

export default TotalInfo;
