/** @format */

import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';

const PREFIX = 'CustomTooltip';

export const classes = {
  date: `${PREFIX}-date`,
  amount: `${PREFIX}-amount`,
  divider: `${PREFIX}-divider`,
};

export const StyledBox = styled(Box)(({theme}) => ({
  backgroundColor: '#E3E3E3',
  padding: theme.spacing(4),
  borderRadius: '8px',
  [`& .${classes.date}`]: {
    color: '#202020',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  [`& .${classes.amount}`]: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-end',
    color: '#202020',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(4),
    position: 'relative',
    left: '-16px',
    width: 'calc(100% + 32px)',
    height: '1px',
    background: '#8B8B8B',
  },
}));

type payloadType = {
  payload: {
    date: string;
    name: string | number;
    amount: string;
  };
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: payloadType[];
  amountEnd?: string;
}

const CustomTooltip = ({active, payload, amountEnd}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <StyledBox>
        {payload[0].payload.date && (
          <Box className={classes.date}>
            {format(payload[0].payload.date, 'dd MMMM', {locale: ru})}
          </Box>
        )}

        {payload[0].payload.amount ? (
          <>
            <Box className={classes.divider} />

            <Box className={classes.amount}>
              {payload[0].payload.amount} {amountEnd ? amountEnd : ''}
            </Box>
          </>
        ) : null}
      </StyledBox>
    );
  }

  return null;
};

export default CustomTooltip;
