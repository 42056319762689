/** @format */

import {Provider} from 'types';

interface token {
  lastError: string | null;
}

const connectedTokens = (tokens: token[]) => {
  return tokens.filter(v => !v.lastError);
};

const authUrl = (provider: Provider, state: string) => {
  if (provider === 'YANDEX') return yandexAuthUrl(state);
  if (provider === 'GOOGLE') return googleAuthUrl(state);
  if (provider === 'FACEBOOK') return facebookAuthUrl(state);
  if (provider === 'VKONTAKTE') return vkontakteAuthUrl(state);
  if (provider === 'VKONTAKTE_ADS') return vkontakteAdsAuthUrl(state);
  if (provider === 'METRIKA') return metrikaAuthUrl(state);

  return null;
};

// https://yandex.ru/dev/oauth/doc/dg/reference/console-client-docpage/
const yandexAuthUrl = (state: string) => {
  const url = new URL('https://oauth.yandex.ru/authorize');

  const callbackUrl = new URL(
    'yandex_callback',
    import.meta.env.VITE_OAUTH_REDIRECT_URI,
  );
  url.searchParams.append('response_type', 'code');
  // https://oauth.yandex.ru/client/7933bd543f714c33aa776c02960ec62b
  // main-complead@yandex.ru
  url.searchParams.append('client_id', '7933bd543f714c33aa776c02960ec62b');
  url.searchParams.append('redirect_uri', callbackUrl.toString());
  url.searchParams.append('state', state);
  url.searchParams.append('force_confirm', 'yes');

  return url;
};

// https://developers.google.com/identity/protocols/oauth2/web-server#httprest
const googleAuthUrl = (state: string) => {
  const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');

  const callbackUrl = new URL(
    'google_callback',
    import.meta.env.VITE_OAUTH_REDIRECT_URI,
  );
  url.searchParams.append('response_type', 'code');
  // https://console.developers.google.com/apis/credentials?project=adsensor-295309&authuser=2&folder=&organizationId=
  url.searchParams.append(
    'client_id',
    '9516161728-ca4tcip7r0a3sjgtc5i1ntab8bga9vft.apps.googleusercontent.com',
  );
  url.searchParams.append('redirect_uri', callbackUrl.toString());
  url.searchParams.append('state', state);
  url.searchParams.append('scope', 'https://www.googleapis.com/auth/adwords');
  url.searchParams.append('access_type', 'offline');
  // Always prompt user so that refresh token is returned every time
  // (it's returned only when user is prompted)
  url.searchParams.append('prompt', 'consent');

  return url;
};

// https://developers.facebook.com/docs/facebook-login/manually-build-a-login-flow
const facebookAuthUrl = (state: string) => {
  const url = new URL('https://www.facebook.com/v8.0/dialog/oauth');

  const callbackUrl = new URL(
    'facebook_callback',
    import.meta.env.VITE_OAUTH_REDIRECT_URI,
  );
  url.searchParams.append('response_type', 'code');
  // https://developers.facebook.com/apps/356459042463755/settings/basic/
  url.searchParams.append('client_id', '356459042463755');
  url.searchParams.append('redirect_uri', callbackUrl.toString());
  url.searchParams.append('state', state);
  url.searchParams.append('scope', 'ads_read,business_management');

  return url;
};

// https://vk.com/dev/authcode_flow_user
const vkontakteAuthUrl = (state: string) => {
  const url = new URL('https://oauth.vk.com/authorize');

  const callbackUrl = new URL(
    'vkontakte_callback',
    import.meta.env.VITE_OAUTH_REDIRECT_URI,
  );
  url.searchParams.append('response_type', 'code');
  // https://vk.com/apps?act=manage
  // https://vk.com/editapp?id=7643263&section=options
  url.searchParams.append('client_id', '7643263');
  url.searchParams.append('redirect_uri', callbackUrl.toString());
  url.searchParams.append('state', state);
  url.searchParams.append('scope', 'ads,offline');
  url.searchParams.append('display', 'popup');

  return url;
};

// https://ads.vk.com/doc/api/info/%D0%B0%D0%B2%D1%82%D0%BE%D1%80%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F%20%D0%B2%20api
const vkontakteAdsAuthUrl = (state: string) => {
  const url = new URL('https://ads.vk.com/hq/settings/access');

  const callbackUrl = new URL(
    'vkontakte_ads_callback',
    import.meta.env.VITE_OAUTH_REDIRECT_URI,
  );
  url.searchParams.append('action', 'oauth2');
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('client_id', '1wisD0Lu3WyFjVYY');
  url.searchParams.append('redirect_uri', callbackUrl.toString());
  url.searchParams.append('state', state);
  url.searchParams.append(
    'scope',
    'read_ads,read_clients,read_payments,read_manager_clients,view_campaigns',
  );

  return url;
};

// https://yandex.ru/dev/oauth/doc/dg/reference/console-client-docpage/
const metrikaAuthUrl = (state: string) => {
  const url = new URL('https://oauth.yandex.ru/authorize');

  const callbackUrl = new URL(
    'metrika_callback',
    import.meta.env.VITE_OAUTH_REDIRECT_URI,
  );
  url.searchParams.append('response_type', 'code');
  // https://oauth.yandex.ru/client/7933bd543f714c33aa776c02960ec62b
  // main-complead@yandex.ru
  url.searchParams.append('client_id', '7933bd543f714c33aa776c02960ec62b');
  url.searchParams.append('redirect_uri', callbackUrl.toString());
  url.searchParams.append('state', state);
  url.searchParams.append('force_confirm', 'yes');

  return url;
};

export default {
  connectedTokens,
  authUrl,
};
