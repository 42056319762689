/** @format */

import {styled} from '@mui/material/styles';

const PREFIX = 'RutubePlayer';

const classes = {
  iframe: `${PREFIX}-iframe`,
};

const Root = styled('iframe')(({theme}) => ({
  [`&.${classes.iframe}`]: {
    width: '600px',
    height: '360px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '240px',
    },
  },
}));

interface Props {
  videoId: string;
}

const RutubePlayer = (props: Props) => {
  return (
    <Root
      className={classes.iframe}
      src={`https://rutube.ru/play/embed/${props.videoId}`}
      allow='clipboard-write'
      // @ts-expect-error Property 'webkitAllowFullScreen' does not exist on type
      webkitAllowFullScreen
      mozallowfullscreen
      allowFullScreen
    />
  );
};

export default RutubePlayer;
