/** @format */

import {createTheme} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface CustomColor {
    main: string;
    light: string;
    dark: string;
    contrastText: string;
  }

  interface Palette {
    figma_gray_light_0?: CustomColor;
    figma_gray_light_1?: CustomColor;
    figma_gray_light_2?: CustomColor;
    figma_gray_light_3?: CustomColor;
    figma_gray_middle_dark?: CustomColor;
    figma_gray_middle_light?: CustomColor;
    figma_gray_dark_0?: CustomColor;
    figma_gray_dark_1?: CustomColor;
    figma_gray_dark_2?: CustomColor;
    figma_gray_dark_3?: CustomColor;
    figma_red_1?: CustomColor;
    figma_red_2?: CustomColor;
    figma_brown_1?: CustomColor;
    figma_brown_2?: CustomColor;
    figma_blue_1?: CustomColor;
    figma_blue_2?: CustomColor;
  }
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    custom_typography: {
      link: Record<string, unknown>;
    };

    backdrop: {
      main: string;
    };
  }
}

const baseTheme = createTheme({
  spacing: 4,
  // https://material-ui.com/customization/palette/#dark-mode
  palette: {
    mode: 'dark',
    background: {
      // This sets <body> background color
      default: '#010202',
    },
    error: {
      // Error text
      main: '#EE4D50',
    },
    success: {
      main: '#749F23',
    },
    primary: {
      // Primary button background
      main: '#F7F8FA',
    },
    secondary: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#141414',
    },
    action: {
      // Button border by default
      active: '#4D4E4E',
      // Button border on hover
      hover: '#808080',
      // Button border on focus
      selected: '#999A9A',
      // Disabled button text
      disabled: '#606161',
      // Disabled button background
      disabledBackground: '#202020',
    },
  },
});

const theme = createTheme(baseTheme, {
  backdrop: {
    main: 'rgba(10, 10, 10, 0.9)',
  },
  custom_typography: {
    // https://github.com/mui-org/material-ui/issues/22257#issuecomment-782557096
    link: {
      color: '#A6A6A6',
      fontSize: 14,
      fontWeight: '700',
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: '#A6A6A6',
      },
    },
  },
  typography: {
    h1: {
      fontSize: 28,
      fontWeight: '700',
      lineHeight: '32px',
      whiteSpace: 'pre-line',
    },
    h2: {
      fontSize: 18,
      fontWeight: '700',
      lineHeight: '22px',
      whiteSpace: 'pre-line',
    },
    body1: {
      fontSize: 16,
      fontWeight: '400',
      lineHeight: '20px',
      whiteSpace: 'pre-line',
    },
    body2: {
      fontSize: 14,
      fontWeight: '400',
      lineHeight: '16px',
      whiteSpace: 'pre-line',
    },
  },
  // https://material-ui.com/customization/components/#global-theme-override
  components: {
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchHeader: {
          color: '#444444',
        },
      },
    },
    MuiPickersBasePicker: {
      styleOverrides: {
        pickerView: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        day: {
          backgroundColor: '#FFFFFF',
          color: '#444444',
        },
        daySelected: {
          backgroundColor: '#444444',
          color: '#AAAAAA',
        },
        dayDisabled: {
          color: '#AAAAAA',
        },
        current: {
          backgroundColor: '#FF8888',
          color: '#444444',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontSize: 16,
          fontWeight: '500',
          lineHeight: '20px',
          textTransform: 'none',
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
            boxShadow: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
        containedPrimary: {
          color: baseTheme.palette.text.secondary,
          '&:hover': {
            backgroundColor: '#E6E6E6',
          },
        },
        containedSizeLarge: {
          fontSize: 16,
          padding: 22,
        },
        outlinedPrimary: {
          borderColor: baseTheme.palette.action.active,
          // palette.primary.main is used by default
          // (since outlined button is inverted contained one)
          color: baseTheme.palette.text.primary,
          '&:hover': {
            backgroundColor: baseTheme.palette.background.default,
            borderColor: baseTheme.palette.action.hover,
          },
        },
        outlinedSizeLarge: {
          fontSize: 16,
          padding: 22,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.primary.main,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: baseTheme.palette.text.primary,
            opacity: 0.5,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.Mui-disabled': {
            '-webkit-text-fill-color': baseTheme.palette.text.secondary,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: baseTheme.palette.background.default,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
          paddingTop: 0,
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          lineHeight: 0,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
        },
        paper: {
          backgroundColor: '#4A4A4A',
          borderRadius: 8,
          '&:hover': {
            backgroundColor: '#4A4A4A',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#424242',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingBottom: baseTheme.spacing(4),
          paddingLeft: baseTheme.spacing(6),
          paddingRight: baseTheme.spacing(6),
          paddingTop: baseTheme.spacing(4),
          '&:hover': {
            backgroundColor: '#393939',
          },
          '&.Mui-selected': {
            backgroundColor: baseTheme.palette.secondary.main,
            color: baseTheme.palette.text.secondary,
            '&:hover': {
              backgroundColor: '#F0F0F0',
            },
          },
        },
      },
    },
    // Used in TextField
    MuiOutlinedInput: {
      styleOverrides: {
        // https://material-ui.com/components/text-fields/#customized-inputs
        root: {
          borderRadius: 8,
          fontSize: 14,
          fontWeight: '400',
          lineHeight: '16px',
          '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.action.hover,
          },
          '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.action.selected,
            borderWidth: 1,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: '#fff',
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      fullWidth: true,
      size: 'large',
    },
    MuiTextField: {
      fullWidth: true,
      required: true,
      variant: 'outlined',
    },
  },
  palette: {
    figma_gray_light_0: baseTheme.palette.augmentColor({
      color: {
        main: '#E3E3E3',
      },
      name: 'figma_gray_light_0',
    }),
    figma_gray_light_1: baseTheme.palette.augmentColor({
      color: {
        main: '#F1F1F1',
      },
      name: 'figma_gray_light_1',
    }),
    figma_gray_light_2: baseTheme.palette.augmentColor({
      color: {
        main: '#FAFAFA',
      },
      name: 'figma_gray_light_2',
    }),
    figma_gray_light_3: baseTheme.palette.augmentColor({
      color: {
        main: '#FCFCFC',
      },
      name: 'figma_gray_light_3',
    }),
    figma_gray_middle_dark: baseTheme.palette.augmentColor({
      color: {
        main: '#8B8B8B',
      },
      name: 'figma_gray_middle_dark',
    }),
    figma_gray_middle_light: baseTheme.palette.augmentColor({
      color: {
        main: '#DFDFDF',
      },
      name: 'figma_gray_middle_light',
    }),
    figma_gray_dark_0: baseTheme.palette.augmentColor({
      color: {
        main: '#111111',
      },
      name: 'figma_gray_dark_0',
    }),
    figma_gray_dark_1: baseTheme.palette.augmentColor({
      color: {
        main: '#202020',
      },
      name: 'figma_gray_dark_1',
    }),
    figma_gray_dark_2: baseTheme.palette.augmentColor({
      color: {
        main: '#2B2B2B',
      },
      name: 'figma_gray_dark_2',
    }),
    figma_gray_dark_3: baseTheme.palette.augmentColor({
      color: {
        main: '#383838',
      },
      name: 'figma_gray_dark_3',
    }),
    figma_red_1: baseTheme.palette.augmentColor({
      color: {
        main: '#CE5959',
      },
      name: 'figma_red_1',
    }),
    figma_red_2: baseTheme.palette.augmentColor({
      color: {
        main: '#FF6164',
      },
      name: 'figma_red_2',
    }),
    figma_brown_1: baseTheme.palette.augmentColor({
      color: {
        main: '#EFAE54',
      },
      name: 'figma_brown_1',
    }),
    figma_brown_2: baseTheme.palette.augmentColor({
      color: {
        main: '#FFBF67',
      },
      name: 'figma_brown_2',
    }),
    figma_blue_1: baseTheme.palette.augmentColor({
      color: {
        main: '#6288EF',
      },
      name: 'figma_blue_1',
    }),
    figma_blue_2: baseTheme.palette.augmentColor({
      color: {
        main: '#779BFD',
      },
      name: 'figma_blue_2',
    }),
  },
});

export default theme;
