/** @format */

import React from 'react';

import {styled} from '@mui/material/styles';

import {Box, Typography} from '@mui/material';

const PREFIX = 'COMPANY';

const classes = {
  stepWrapper: `${PREFIX}-stepWrapper`,
  stepHeader: `${PREFIX}-stepHeader`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.stepWrapper}`]: {
    marginTop: theme.spacing(16),
  },
  [`& .${classes.stepHeader}`]: {
    color: '#F1F1F1',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0.2px',
  },
}));

interface Props {
  header: React.ReactNode;
  children: React.ReactNode;
}

const StepWrapper = (props: Props) => {
  return (
    <StyledBox>
      <Box
        sx={{
          mt: 16,
        }}
        className={classes.stepWrapper}
      >
        <Typography className={classes.stepHeader}>{props.header}</Typography>

        {props.children}
      </Box>
    </StyledBox>
  );
};

export default StepWrapper;
