/** @format */
import {Box} from '@mui/material';

import {
  AreaChart as RechartsAreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import CustomTooltip from './CustomTooltip';
import {
  FILL,
  STROKE,
  STROKE_WIDTH,
  GRID_STROKE,
  BRUSH_STROKE,
} from './constants';

interface Props {
  data: {
    name: string;
    amount: number;
  }[];
  withYAxis?: boolean;
  withCartesianGrid?: boolean;
  withBrush?: boolean;
  withWeekends?: boolean;
  amountEnd?: React.ReactNode | string;
}

const AreaChart = (props: Props) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 400,
      }}
    >
      <ResponsiveContainer width='100%' height='100%'>
        <RechartsAreaChart
          width={150}
          height={40}
          data={props.data}
          syncId={'areaChart'}
        >
          {props.withCartesianGrid && <CartesianGrid stroke={GRID_STROKE} />}

          <XAxis dataKey='name' />

          {props.withYAxis && <YAxis />}

          <Tooltip content={<CustomTooltip amountEnd={props.amountEnd} />} />

          <Legend />

          {props.withWeekends && (
            <Area
              dataKey='weekendAmount'
              type='bumpX'
              fill={FILL}
              stroke={'transparent'}
              fillOpacity={0.7}
              strokeWidth={STROKE_WIDTH}
              animationDuration={300}
            />
          )}

          <Area
            dataKey='amount'
            type='bumpX'
            fill={FILL}
            stroke={STROKE}
            strokeWidth={STROKE_WIDTH}
            animationDuration={300}
          />

          {props.withBrush && (
            <Brush dataKey='name' height={30} stroke={BRUSH_STROKE} />
          )}
        </RechartsAreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default AreaChart;
