/** @format */
import {useState, useEffect, useCallback} from 'react';

import {Box, Button, LinearProgress, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import {add, format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {differenceInDays} from 'date-fns/differenceInDays';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';

import {gql, useQuery} from '@apollo/client';

import {Trans} from 'react-i18next';
import {clsx} from 'clsx';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import DateRange from 'components/DateRange';
import AreaChart from 'components/charts/AreaChart';

import {useMixpanelContext} from 'contexts/MixpanelContext';

import WideLayout from 'layouts/WideLayout';

import TotalInfo from './TotalInfo';

const PREFIX = 'DIGITAL_CAST';

const classes = {
  controls: `${PREFIX}-controls`,
  controlsWrapper: `${PREFIX}-controlsWrapper`,
  controlsWrapperRounded: `${PREFIX}-controlsWrapperRounded`,
  controlButton: `${PREFIX}-controlButton`,
  swipeButton: `${PREFIX}-swipeButton`,
  controlButtonActive: `${PREFIX}-controlButtonActive`,
  controlButtonCustom: `${PREFIX}-controlButtonCustom`,
  dateButtonIcon: `${PREFIX}-dateButtonIcon`,
  dateButtonIconActive: `${PREFIX}-dateButtonIconActive`,
  graphHeading: `${PREFIX}-graphHeading`,
  rubleIcon: `${PREFIX}-rubleIcon`,
};

const StyledBox = styled(Box)(({theme}) => ({
  display: 'flex',
  marginTop: theme.spacing(4),
  flexWrap: 'wrap',
  [`& .${classes.controls}`]: {
    position: 'sticky',
    top: 0,
    width: '100%',
    padding: theme.spacing(4, 0),
    background: '#010202',
    zIndex: 2,
    display: 'flex',
  },
  [`& .${classes.controlsWrapper}`]: {
    height: '48px',
    marginRight: theme.spacing(4),
    borderRadius: '8px 0 0 8px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 0,
      '& > *': {
        width: '50%',
      },
    },
  },
  [`& .${classes.controlsWrapperRounded}`]: {
    borderRadius: '8px ',
  },
  [`& .${classes.graphHeading}`]: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
    color: '#F1F1F1',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.24px',
  },
  [`& .${classes.controlButton}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '143%',
    padding: theme.spacing(3.5, 4),
    letterSpacing: '0.25px',
    borderRadius: 0,
    background: '#202020',
  },

  [`& .${classes.swipeButton}`]: {
    color: '#8B8B8B',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '143%',
    padding: theme.spacing(3.5, 4),
    letterSpacing: '0.25px',
    borderRadius: 0,
    background: '#202020',
    ['&:nth-of-type(2)']: {
      transform: 'rotate(180deg)',
    },
  },
  [`& .${classes.controlButtonCustom}`]: {
    borderRadius: '0 8px 8px 0',
  },
  [`& .${classes.controlButtonActive}`]: {
    color: '#F1F1F1',
    background: '#2B2B2B',
  },
  [`& .${classes.dateButtonIcon}`]: {
    fontSize: '16px',
    color: '#8B8B8B',
    marginRight: theme.spacing(2.5),
  },
  [`& .${classes.dateButtonIconActive}`]: {
    color: '#F1F1F1',
  },
  [`& .${classes.rubleIcon}`]: {
    color: '#202020',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

type Period = 'year' | 'quarter' | 'month' | 'week' | 'custom';

const PERIODS: {
  key: Period;
  name: string;
}[] = [
  {
    key: 'year',
    name: '365 дней',
  },
  {
    key: 'quarter',
    name: '90 дней',
  },
  {
    key: 'month',
    name: '30 дней',
  },
  {
    key: 'week',
    name: '7 дней',
  },
];

type Discreteness = 'days' | 'weeks' | 'months';

const DISCRETENESSES: {
  key: Discreteness;
  name: string;
}[] = [
  {
    key: 'days',
    name: 'Дни',
  },
  {
    key: 'weeks',
    name: 'Недели',
  },
  {
    key: 'months',
    name: 'Месяцы',
  },
];

type GraphType = 'spent' | 'impressions' | 'clicks' | 'cpc' | 'ctr';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($startDate: Date!, $endDate: Date!) {
    currentUser {
      currentCompany {
        graphStats(startDate: $startDate, endDate: $endDate) {
          currency
          platform
          extendedPlatform
          date
          spent
          impressions
          clicks
          cpc
          ctr
        }
      }
    }
  }
`;

const WEEK_DAYS = 7;
const MONTHS_DAYS = 30;
const QUARTER_DAYS = 90;
const YEAR_DAYS = 365;

const DigitalCast = () => {
  const {mixpanel} = useMixpanelContext();

  const now = new Date();
  const defaultStartDate = add(now, {days: -31});
  const defaultEndDate = now;

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [activePeriod, setActivePeriod] = useState<Period>('quarter');
  const [activeDiscreteness, setActiveDiscreteness] =
    useState<Discreteness>('days');

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {
      startDate: format(startDate || defaultStartDate, 'yyyy-MM-dd'),
      endDate: format(endDate || defaultEndDate, 'yyyy-MM-dd'),
    },
  });

  const handleDateRangeConfirm = (startDate: Date, endDate: Date) => {
    mixpanel.track('confirm_digital_cast_date_range');

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePeriodChange = (key: Period) => {
    setActivePeriod(key);
  };

  useEffect(() => {
    if (activePeriod === 'month') {
      setStartDate(add(now, {days: -MONTHS_DAYS}));
    }
    if (activePeriod === 'quarter') {
      setStartDate(add(now, {days: -QUARTER_DAYS}));
    }
    if (activePeriod === 'year') {
      setStartDate(add(now, {days: -YEAR_DAYS}));
    }
    if (activePeriod === 'week') {
      setStartDate(add(now, {days: -WEEK_DAYS}));
    }
    setEndDate(now);

    setActiveDiscreteness('days');
  }, [activePeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDiscretenessChange = (key: Discreteness) => {
    setActiveDiscreteness(key);
  };

  const periodConstant = useCallback((period: Period) => {
    if (period === 'month') {
      return MONTHS_DAYS;
    }
    if (period === 'quarter') {
      return QUARTER_DAYS;
    }
    if (period === 'year') {
      return YEAR_DAYS;
    }
    if (period === 'week') {
      return WEEK_DAYS;
    }

    return 0;
  }, []);

  const handlePeriodSwipe = useCallback(
    (value: number) => {
      const constant = periodConstant(activePeriod);

      setStartDate(
        add(startDate, {days: value === -1 ? -constant : +constant}),
      );
      setEndDate(add(endDate, {days: value === -1 ? -constant : +constant}));
    },
    [activePeriod, startDate, endDate, periodConstant],
  );

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const sumSpentByDate = (
    data: {
      date: string;
      spent: number;
    }[],
  ) => {
    const result = {};

    data.forEach(entry => {
      const {date, spent} = entry;

      if (result[date]) {
        result[date] += spent;
      } else {
        result[date] = spent;
      }
    });

    return result;
  };

  const sumByChunks = (
    data: {
      name: string;
      date: string;
      amount: number;
    }[],
    chunkSize: number,
  ) => {
    const result = [];

    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize);
      const chunkSum = chunk.reduce((sum, item) => sum + item.amount, 0);

      result.push({
        amount: chunkSum,
        date: chunk[chunk.length - 1].date,
        name: chunk[chunk.length - 1].name,
      });
    }

    return result;
  };

  const formattedGraphStats = (
    discreteness: Discreteness,
    graphType: GraphType,
  ) => {
    const {graphStats} = data.currentUser.currentCompany;

    const statByGraphType = stat => {
      if (graphType === 'spent') {
        return stat.spent;
      }
      if (graphType === 'impressions') {
        return stat.impressions;
      }
      if (graphType === 'clicks') {
        return stat.clicks;
      }
      if (graphType === 'cpc') {
        return stat.cpc;
      }
      if (graphType === 'ctr') {
        return stat.ctr;
      }

      return 0;
    };

    const formattedStats = graphStats.map(stat => ({
      date: stat.date,
      spent: Math.round(statByGraphType(stat)),
    }));

    const graphStatsSummedByDate = sumSpentByDate(formattedStats);

    const stats = Object.keys(graphStatsSummedByDate).map(key => ({
      name: format(new Date(key), 'dd'),
      date: key,
      amount: graphStatsSummedByDate[key],
    }));

    if (discreteness === 'weeks') {
      return sumByChunks(stats, WEEK_DAYS);
    }
    if (discreteness === 'months') {
      return sumByChunks(stats, MONTHS_DAYS);
    }
    if (discreteness === 'days') {
      return stats;
    }

    return stats;
  };

  const renderChart = (graphType: GraphType) => {
    const formattedStats = formattedGraphStats(activeDiscreteness, graphType);

    const graphData = formattedStats.map(stat => {
      const dayOfWeek = new Date(stat.date).getDay();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

      return {
        ...stat,
        weekendAmount: isWeekend ? stat.amount : null,
      };
    });

    return (
      <AreaChart
        data={graphData}
        withYAxis
        withCartesianGrid
        withWeekends
        amountEnd={
          graphType === 'spent' ? (
            <CurrencyRubleIcon className={classes.rubleIcon} />
          ) : undefined
        }
      />
    );
  };

  const formatAmountToNumberFormat = (amount: number) => {
    return new Intl.NumberFormat('ru-RU').format(amount);
  };

  const renderSpentInfo = () => {
    const {graphStats} = data.currentUser.currentCompany;

    const period = `${format(startDate, 'dd MMMM', {locale: ru})} - ${format(endDate, 'dd MMMM', {locale: ru})}`;
    const spentAmount = graphStats.reduce((acc, cur) => {
      return (acc += cur.spent);
    }, 0);
    const periodDays = differenceInDays(endDate, startDate);
    const spentPerDay = Math.round(spentAmount / periodDays);

    return (
      <TotalInfo
        period={period}
        amount={formatAmountToNumberFormat(spentAmount)}
        periodDays={periodDays}
        spentPerDay={formatAmountToNumberFormat(spentPerDay)}
        isSpentInfo
      />
    );
  };

  const renderImpressionsInfo = () => {
    const {graphStats} = data.currentUser.currentCompany;

    const impressionsAmount = graphStats.reduce((acc, cur) => {
      return (acc += cur.impressions);
    }, 0);
    const periodDays = differenceInDays(endDate, startDate);

    return (
      <TotalInfo
        amount={formatAmountToNumberFormat(impressionsAmount)}
        periodDays={periodDays}
      />
    );
  };

  const renderClicksInfo = () => {
    const {graphStats} = data.currentUser.currentCompany;

    const clicksAmount = graphStats.reduce((acc, cur) => {
      return (acc += cur.clicks);
    }, 0);
    const periodDays = differenceInDays(endDate, startDate);

    return (
      <TotalInfo
        amount={formatAmountToNumberFormat(clicksAmount)}
        periodDays={periodDays}
      />
    );
  };

  const renderCPCInfo = () => {
    const {graphStats} = data.currentUser.currentCompany;

    const cpcAmount = Math.round(
      graphStats.reduce((acc, cur) => {
        return (acc += cur.cpc);
      }, 0) / graphStats.length,
    );

    return (
      <TotalInfo amount={formatAmountToNumberFormat(cpcAmount)} isAverageInfo />
    );
  };

  const renderCTRInfo = () => {
    const {graphStats} = data.currentUser.currentCompany;

    const ctrAmount = Math.round(
      graphStats.reduce((acc, cur) => {
        return (acc += cur.ctr);
      }, 0) / graphStats.length,
    );

    return (
      <TotalInfo amount={formatAmountToNumberFormat(ctrAmount)} isAverageInfo />
    );
  };

  return (
    <WideLayout>
      <StyledBox>
        <Box className={classes.controls}>
          <Box className={classes.controlsWrapper}>
            {PERIODS.map(period => (
              <Button
                key={period.key}
                className={clsx(
                  classes.controlButton,
                  activePeriod === period.key && classes.controlButtonActive,
                )}
                onClick={() => handlePeriodChange(period.key)}
              >
                <Trans>{period.name}</Trans>
              </Button>
            ))}
            <Button
              className={clsx(
                classes.controlButton,
                classes.controlButtonCustom,
                activePeriod === 'custom' && classes.controlButtonActive,
              )}
              onClick={() => {
                handlePeriodChange('custom');
              }}
            >
              <DateRangeIcon
                className={clsx(
                  classes.dateButtonIcon,
                  activePeriod === 'custom' && classes.dateButtonIconActive,
                )}
              />
              {activePeriod !== 'custom' ? (
                <Trans>Период</Trans>
              ) : (
                <DateRange
                  startDate={startDate}
                  endDate={endDate}
                  onConfirm={handleDateRangeConfirm}
                />
              )}
            </Button>
          </Box>

          <Box
            className={clsx(
              classes.controlsWrapper,
              classes.controlsWrapperRounded,
            )}
          >
            {DISCRETENESSES.map(discreteness => (
              <Button
                key={discreteness.key}
                className={clsx(
                  classes.controlButton,
                  activeDiscreteness === discreteness.key &&
                    classes.controlButtonActive,
                )}
                onClick={() => handleDiscretenessChange(discreteness.key)}
              >
                <Trans>{discreteness.name}</Trans>
              </Button>
            ))}
          </Box>

          <Box
            className={clsx(
              classes.controlsWrapper,
              classes.controlsWrapperRounded,
            )}
          >
            <Button
              className={classes.swipeButton}
              onClick={() => handlePeriodSwipe(-1)}
            >
              <ArrowBackIosNewIcon />
            </Button>
            <Button
              className={classes.swipeButton}
              onClick={() => handlePeriodSwipe(1)}
            >
              <ArrowBackIosNewIcon />
            </Button>
          </Box>
        </Box>
        <Box sx={{width: '100%'}}>
          <Typography className={classes.graphHeading}>
            <Trans>Расход</Trans>
          </Typography>

          {renderSpentInfo()}

          {renderChart('spent')}
        </Box>

        <Box sx={{width: '100%'}}>
          <Typography className={classes.graphHeading}>
            <Trans>Показы</Trans>
          </Typography>

          {renderImpressionsInfo()}

          {renderChart('impressions')}
        </Box>

        <Box sx={{width: '100%'}}>
          <Typography className={classes.graphHeading}>
            <Trans>Клики</Trans>
          </Typography>

          {renderClicksInfo()}

          {renderChart('clicks')}
        </Box>

        <Box sx={{width: '100%'}}>
          <Typography className={classes.graphHeading}>
            <Trans>CPC</Trans>
          </Typography>

          {renderCPCInfo()}

          {renderChart('cpc')}
        </Box>

        <Box sx={{width: '100%'}}>
          <Typography className={classes.graphHeading}>
            <Trans>CTR</Trans>
          </Typography>

          {renderCTRInfo()}

          {renderChart('ctr')}
        </Box>
      </StyledBox>
    </WideLayout>
  );
};

export default DigitalCast;
