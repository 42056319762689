/** @format */
import {useState, useEffect, useCallback} from 'react';

import {Box, LinearProgress, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import Alert from '@mui/material/Alert';

import InformationDialog from 'components/dialogs/InformationDialog';

import DemoModal from 'components/DemoModal';
import WizardSidebar from 'components/MediaplanSidebar/WizardSidebar';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

import {useMixpanelContext} from 'contexts/MixpanelContext';

import routerHelpers from 'helpers/routerHelpers';
import connectHelpers from 'helpers/connectHelpers';

import {Provider} from 'types';

import type {Token} from '../../TokenStep';

import NameStep from '../../NameStep';
import TokenStep from '../../TokenStep';
import WizardHeader from '../../WizardHeader';
import StepWrapper from '../../StepWrapper';

import {STATS_PROVIDERS} from '../Created';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($id: ID) {
    currentUser {
      restrictions {
        aliveCompaniesCount
        totalCompaniesCount
      }
      roles
      subscription {
        isTrial
      }
      totalCompaniesCount
      company(id: $id) {
        id
        name
        tokens {
          id
          lastError
          provider
          accounts {
            id
            name
          }
        }
      }
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: ID!, $name: String!) {
    updateCompany(id: $id, name: $name) {
      id
      uuid
      name
      tokens {
        id
        lastError
        provider
      }
    }
  }
`;

const DISCONNECT_PROVIDER = gql`
  mutation DisconnectProvider($companyId: ID!, $provider: OauthProvider!) {
    disconnectProvider(companyId: $companyId, provider: $provider)
  }
`;

const DELETE_PROVIDER_TOKEN = gql`
  mutation DeleteProviderToken($provider: OauthProvider!, $companyId: ID!) {
    deleteProviderToken(provider: $provider, companyId: $companyId)
  }
`;

const START = gql`
  mutation Start($companyId: ID!) {
    start(companyId: $companyId)
  }
`;

const SET_WIZARD_FINISHED = gql`
  mutation SetWizardFinished {
    setWizardFinished {
      id
    }
  }
`;

const WizardCreated = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();

  const {mixpanel} = useMixpanelContext();

  let [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const confirm = searchParams.get('confirm');

  const [isOnConfirmFired, setIsOnConfirmFired] = useState(false);
  const [projectName, setPojectName] = useState('');
  const [saveCompanyError, setSaveCompanyError] = useState('');
  const [activeProvider, setActiveProvider] = useState<Provider | null>(
    'YANDEX',
  );
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

  const [showDemoModal, setShowDemoModal] = useState(false);

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER, {
    variables: {id: companyId},
  });

  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const [disconnectProvider, {loading: loadingDisconnectProvider}] =
    useMutation(DISCONNECT_PROVIDER);
  const [deleteProviderToken] = useMutation(DELETE_PROVIDER_TOKEN);
  const [start] = useMutation(START, {
    variables: {companyId},
  });
  const [setWizardFinished] = useMutation(SET_WIZARD_FINISHED);

  routerHelpers.useCallbackOnReturn(refetch);

  const deleteConfirm = useCallback(() => {
    if (searchParams.has('confirm')) {
      searchParams.delete('confirm');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (!data) return;

    const {company} = data.currentUser;

    if (company.tokens.length === 0) return;

    const token = company.tokens.find(
      (token: Token) => token.provider === confirm,
    );

    if (token) {
      if (isOnConfirmFired) return;

      const {provider, lastError} = token;
      const isConnected = lastError == null;

      if (!isConnected) return;

      setIsOnConfirmFired(true);

      deleteConfirm();

      navigate(`subaccounts?companyId=${companyId}&provider=${provider}`, {
        state: {background: location},
      });
    }
  }, [
    data,
    confirm,
    companyId,
    location,
    navigate,
    isOnConfirmFired,
    setSearchParams,
    searchParams,
    deleteConfirm,
  ]);

  useEffect(() => {
    if (!data) return;

    const {company} = data.currentUser;

    const token = company.tokens.find(
      (token: Token) => token.provider === confirm,
    );
    const {lastError} = token || {};

    if (token && lastError) {
      setShowErrorDialog(true);
    }
  }, [data, confirm]);

  useEffect(() => {
    if (!data) return;

    const {company} = data.currentUser;
    setPojectName(company.name);
  }, [data]);

  if (loading || loadingDisconnectProvider) {
    return <LinearProgress style={{flex: 1}} />;
  }
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {company, roles} = data.currentUser;
  const tokens: Token[] = company.tokens || [];

  const adTokens = tokens.filter(
    token => !STATS_PROVIDERS.includes(token.provider),
  );
  const statTokens = tokens.filter(token =>
    STATS_PROVIDERS.includes(token.provider),
  );

  const displayMetrika = roles.includes('METRIKA');
  const displayWebdav = roles.includes('WEBDAV');

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setPojectName(e.target.value);

      mixpanel.track('click_wizard_update_company_button');

      await updateCompany({
        variables: {id: companyId, name: e.target.value},
      });
    } catch (e) {
      const message = e instanceof Error ? e.message : 'Unknown error.';
      setSaveCompanyError(message);
    }
  };

  const handleAdCompanyClick = (key: Provider | null) => {
    setActiveProvider(key);
  };

  const getState = (provider: Provider) => {
    const url = new URL(window.location.href);
    url.searchParams.append('confirm', provider);

    const returnUrl = url.toString();
    const state = {companyId, returnUrl};

    return window.btoa(JSON.stringify(state));
  };

  const handleAdConnect = (provider: Provider) => {
    if (roles.includes('DEMO')) {
      setShowDemoModal(true);
      return;
    }

    mixpanel.track('click_wizard_before_connect_continue_button', {
      provider,
    });

    const authUrl = connectHelpers.authUrl(provider, getState(provider));

    if (!authUrl) return;

    window.location.href = authUrl.href;
  };

  const handleEditSubbaccounts = (provider: Provider) => {
    if (roles.includes('DEMO')) {
      setShowDemoModal(true);
      return;
    }

    navigate(`subaccounts?companyId=${companyId}&provider=${provider}`, {
      state: {background: location},
    });
  };

  const handleRemoveToken = async (provider: Provider) => {
    if (roles.includes('DEMO')) {
      setShowDemoModal(true);
      return;
    }

    mixpanel.track('click_disconnect_button', {provider: provider});

    await disconnectProvider({variables: {provider, companyId}});

    refetch();
  };

  const renderErrorDialog = () => {
    if (!data) return;

    const token = company.tokens.find(
      (token: Token) => token.provider === confirm,
    );

    if (!token) return;

    const handleClose = async () => {
      setShowErrorDialog(false);

      await deleteProviderToken({
        variables: {provider: token.provider, companyId: companyId},
      });

      deleteConfirm();

      refetch();
    };

    return (
      <InformationDialog
        open={showErrorDialog}
        onClose={handleClose}
        title={t(
          'Ошибка при подключении рекламной кампании',
          'Ошибка при подключении рекламной кампании',
        )}
        body={token.lastError}
      />
    );
  };

  const handleCloseDemoModal = () => {
    setShowDemoModal(false);
  };

  const handleSaveAll = async () => {
    if (!data) return;

    const {isWizardFinished} = data.currentUser;

    if (!isWizardFinished) {
      await setWizardFinished();
    }

    await start();

    navigate('/');
  };

  const tokensHaveAccounts =
    tokens.length > 0 ? tokens.every(t => t.accounts.length > 0) : false;

  return (
    <>
      <WizardSidebar activeStep={'CREATE'} />

      <WizardHeader />

      <NameStep
        onChange={handleChange}
        saveCompanyError={saveCompanyError}
        projectName={projectName}
        isProjectCreated
      />

      <StepWrapper
        header={
          <>
            2. <Trans>Рекламная система</Trans>
          </>
        }
      >
        {adTokens.length > 0 ? (
          adTokens.map((token, index) => (
            <TokenStep
              key={index}
              token={token}
              onEditSubbaccounts={handleEditSubbaccounts}
              onRemoveToken={handleRemoveToken}
              onAdCompanyClick={handleAdCompanyClick}
              onAdConnect={handleAdConnect}
            />
          ))
        ) : (
          <TokenStep
            token={null}
            activeProvider={activeProvider}
            onEditSubbaccounts={handleEditSubbaccounts}
            onRemoveToken={handleRemoveToken}
            onAdCompanyClick={handleAdCompanyClick}
            onAdConnect={handleAdConnect}
          />
        )}
      </StepWrapper>

      {!activeProvider && (
        <Box sx={{mt: 12}}>
          <Typography>
            <Trans>
              Без рекламной системы проект будет ограничен в функционале
            </Trans>
          </Typography>
        </Box>
      )}
      {(tokens.filter(token => !STATS_PROVIDERS.includes(token.provider))
        .length > 0 ||
        !activeProvider) &&
        (displayMetrika || displayWebdav) && (
          <StepWrapper
            header={
              <>
                3. <Trans>Аналитика</Trans>
              </>
            }
          >
            {statTokens.length > 0 ? (
              statTokens.map((token, index) => (
                <TokenStep
                  key={index}
                  token={token}
                  onEditSubbaccounts={handleEditSubbaccounts}
                  onRemoveToken={handleRemoveToken}
                  onAdCompanyClick={handleAdCompanyClick}
                  onAdConnect={handleAdConnect}
                  isAnalyticsStep
                />
              ))
            ) : (
              <TokenStep
                token={null}
                activeProvider={'METRIKA'}
                onEditSubbaccounts={handleEditSubbaccounts}
                onRemoveToken={handleRemoveToken}
                onAdCompanyClick={handleAdCompanyClick}
                onAdConnect={handleAdConnect}
                isAnalyticsStep
              />
            )}
          </StepWrapper>
        )}

      {tokensHaveAccounts && (
        <Box
          sx={{
            mt: 24,
            mb: 24,
          }}
        >
          <PrimaryMediumButton onClick={handleSaveAll}>
            <Trans>Сохранить и вернуться на главную</Trans>
          </PrimaryMediumButton>
        </Box>
      )}

      {renderErrorDialog()}

      {showDemoModal && <DemoModal onClose={handleCloseDemoModal} />}
    </>
  );
};

export default WizardCreated;
