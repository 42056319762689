/** @format */

import {styled} from '@mui/material/styles';
import {Trans} from 'react-i18next';
import {Box, Typography} from '@mui/material';

import RutubePlayer from 'components/RutubePlayer';

const PREFIX = 'HEADER';

const classes = {
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  videoWrapper: `${PREFIX}-videoWrapper`,
  videoTitle: `${PREFIX}-videoTitle`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`& .${classes.title}`]: {
    color: '#F1F1F1',
    fontSize: '32px',
    fontWeight: 300,
    lineHeight: '40px',
  },
  [`& .${classes.subtitle}`]: {
    color: '#F1F1F1',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.24px',
  },
  [`& .${classes.videoWrapper}`]: {
    maxWidth: '632px',
    padding: theme.spacing(4),
    borderRadius: '24px',
    background: '#202020',
  },
  [`& .${classes.videoTitle}`]: {
    color: '#F1F1F1',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.36px',
    marginBottom: theme.spacing(4),
  },
}));

const WizardHeader = () => {
  return (
    <StyledBox>
      <Typography className={classes.title}>
        <Trans>Добро пожаловать</Trans>
      </Typography>

      <Box sx={{mt: 16}}>
        <Typography className={classes.subtitle}>
          <Trans>
            Здесь вы можете самостоятельно настроить свой первый проект
          </Trans>
        </Typography>
      </Box>

      <Box sx={{mt: 8}} className={classes.videoWrapper}>
        <Typography className={classes.videoTitle}>
          <Trans>Обучающее видео на 2 минуты</Trans>
        </Typography>

        <RutubePlayer videoId='f129d098bdf76092f2a4838a4dceedd9' />
      </Box>
    </StyledBox>
  );
};

export default WizardHeader;
