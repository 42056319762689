/** @format */
import {useState} from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import makeStyles from '@mui/styles/makeStyles';
import {useFlags} from 'launchdarkly-react-client-sdk';

import OutlinedLargeButton from 'components/buttons/OutlinedLargeButton';
import CreatePasswordFieldFilled from 'components/inputs/CreatePasswordFieldFilled';
import PhoneNumberFieldFilled from 'components/inputs/PhoneNumberFieldFilled';
import LegacyTextFieldFilled from 'components/inputs/LegacyTextFieldFilled';
import PrimaryLargeButton from 'components/buttons/PrimaryLargeButton';
import {useAuthContext} from 'contexts/AuthContext';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import Footer from './Footer';

const SIGN_UP = gql`
  mutation SignUp(
    $phone: String!
    $password: String!
    $referrer: String
    $lang: String!
    $promoCode: String
  ) {
    signUpWithCompany(
      phone: $phone
      password: $password
      referrer: $referrer
      lang: $lang
      promoCode: $promoCode
    ) {
      token
      user {
        id
        uuid
        phone
        roles
        insertedAt
        firstCompany {
          id
          uuid
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.primary,
  },
  signInButton: {
    color: '#000',
    '&:hover': {
      background: '#fff',
    },
  },
  signUpButton: {
    color: '#fff',
    backgroundColor: 'rgb(12, 12, 12)',
    '&:hover': {
      backgroundColor: 'rgb(12, 12, 12)',
    },
  },
}));

const SignUp = () => {
  const navigate = useNavigate();

  const [cookies] = useCookies();
  const classes = useStyles();

  const authContext = useAuthContext();
  const {mixpanel} = useMixpanelContext();
  const {switchCompany} = useCompanyContext();

  const {isOneRkEnabled} = useFlags();

  const [state, setState] = useState({});
  const [signUpError, setSignUpError] = useState('');

  const [signUp, {loading}] = useMutation(SIGN_UP);

  const {i18n, t} = useTranslation();

  const handleChange = event => {
    const {name, value} = event.target;
    setState({...state, [name]: value});
  };

  const handleChangePhone = value => {
    setState({...state, phone: value});
  };

  const handleSubmit = async event => {
    mixpanel.track('click_sign_up_button');
    event.preventDefault();

    // https://stackoverflow.com/questions/59465864
    try {
      const {phone, password, promoCode} = state;
      // Variables with undefined value are removed from GraphQL query
      const referrer = cookies.partner || null;

      const {data} = await signUp({
        // NumberFormat adds trailing spaces
        variables: {
          phone: phone.trim(),
          password,
          referrer,
          lang: i18n.language,
          promoCode,
        },
      });

      await authContext.signUp(data.signUpWithCompany);
      const company = data.signUpWithCompany.user.firstCompany;
      switchCompany(company);

      const companyId = company.id;
      navigate(
        {
          pathname: isOneRkEnabled
            ? '/home/project/wizard/created'
            : '/wizard/connect',
          search: `?companyId=${companyId}&video=1`,
        },
        {
          replace: true,
        },
      );
    } catch (e) {
      if (e.message === 'invalid_promo_code') {
        setSignUpError('Вы ввели неверный промокод, попробуйте еще раз');
      } else {
        setSignUpError(e.message);
      }
    }
  };

  return (
    <>
      <Typography variant='h1' className={classes.text}>
        <Trans>Регистрация</Trans>
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mt={5}>
          <PhoneNumberFieldFilled
            error={Boolean(signUpError)}
            helperText={signUpError}
            value={state.phone}
            inputProps={{name: 'phone'}}
            onChange={handleChangePhone}
          />
        </Box>

        <Box mt={3}>
          <CreatePasswordFieldFilled
            value={state.password || ''}
            error={Boolean(signUpError)}
            inputProps={{name: 'password'}}
            onChange={handleChange}
          />
        </Box>
        <Box mt={3}>
          <LegacyTextFieldFilled
            value={state.promoCode || ''}
            error={Boolean(signUpError)}
            placeholder={t('Промокод', 'Промокод')}
            fullWidth
            inputProps={{name: 'promoCode'}}
            onChange={handleChange}
          />
        </Box>

        <Box mt={5}>
          <PrimaryLargeButton
            color='primary'
            disabled={loading || !state.phone || !state.password}
            type='submit'
            variant='contained'
            fullWidth
            className={classes.signUpButton}
            endIcon={<ArrowForwardIcon />}
          >
            <Trans>Отправить</Trans>
          </PrimaryLargeButton>
        </Box>
        <Box mt={2}>
          <OutlinedLargeButton
            component={RouterLink}
            to='/auth/sign-in'
            fullWidth
            className={classes.signInButton}
          >
            <Trans>Авторизация</Trans>
          </OutlinedLargeButton>
        </Box>
      </form>

      {i18n.language === 'ru' && <Footer />}
    </>
  );
};

export default SignUp;
