/** @format */

import {Close} from '@mui/icons-material';
import {IconButton, Typography} from '@mui/material';

import {sensorName} from 'helpers/sensor_names';

import {StyledBox, classes} from './styles';

interface SensorItemProps {
  id: string;
  onRemove: (id: string) => void;
}
export const SensorItem = ({id, onRemove}: SensorItemProps) => {
  const label = sensorName(id);
  if (!label) return null;

  const handleRemove = () => onRemove(id);

  return (
    <StyledBox className={classes.sensorListItem}>
      <Typography className={classes.sensorListItemLabel}>{label}</Typography>
      <IconButton onClick={handleRemove}>
        <Close className={classes.sensorListItemIcon} />
      </IconButton>
    </StyledBox>
  );
};
